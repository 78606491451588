import get from "lodash/get";
import upperFirst from "lodash/upperFirst";

export const MULTISELECT_PRESENTATION_TYPE = {
  LIST: "list",
  SELECT: "select"
};

export const MULTISELECT_PRESENTATION_TYPE_OPTIONS = [
  {
    label: upperFirst(MULTISELECT_PRESENTATION_TYPE.LIST),
    uid: MULTISELECT_PRESENTATION_TYPE.LIST,
    description: "List of checkboxes to select from."
  },
  {
    label: upperFirst(MULTISELECT_PRESENTATION_TYPE.SELECT),
    uid: MULTISELECT_PRESENTATION_TYPE.SELECT,
    description: "Select dropdown of options."
  }
];

export const getMultiselectProps = ({
  customStyles,
  customStylesClasses,
  isMulti
}) => ({
  className: "react-select-container",
  classNamePrefix: isMulti ? "multiselect--autoHeight" : "multiselect",
  styles: {
    menu: (provided) => ({
      ...provided,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      marginTop: 2
    }),
    valueContainer: (provided) => {
      return {
        ...provided,
        cursor: "pointer",
        padding: "0px 12px"
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? "whitesmoke" : "white"
      };
    },
    control: (provided, state) => {
      const result = {
        ...provided,
        "border": 0,
        "boxShadow":
          "0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,.07),0 1px 1.5px 0 rgba(0,0,0,.05)",
        "transition":
          "box-shadow .08s ease-in,color .08s ease-in,filter 50000s",
        "background": "white",
        "WebkitAppearance": "none",
        "MozAppearance": "none",
        "appearance": "none",
        "&:hover": {
          background: "whitesmoke"
        },
        ...get(customStylesClasses, "control", {})
      };
      if (state.isFocused) {
        result.outline = "none";
        result.boxShadow = `0 0 0 1px rgb(50 151 211 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%), 0 0 0 4px rgb(50 151 211 / 30%) !important`;
        result.zIndex = 1;
      } else {
        result.zIndex = 0;
      }
      return result;
    },
    ...customStyles
  }
});
