import React from "react";
import classnames from "classnames";
import Image from "components/Image";
import { PRIORITY_FIT_CONTAIN } from "utils/constants/ui";
import { IMAGE_DIMENSIONS } from ".";

const LineItems = ({ lineItems, hasSecondaryLines, collapseImages }) => {
  return lineItems.map((item, itemIx) => {
    /**
     * Note: The last line item will be the Subtotal line
     * so we omit that and the first line item from the remainder count
     * - For flexible prices - a line item will be unshifted to the start of the collection
     */
    const lastLineItem = lineItems.length - 1 === itemIx;
    const hasSub = item.sublabel || item.subvalue;

    return (
      <div
        key={itemIx}
        className={classnames("flex flex-row w-100", {
          pt3: itemIx,
          pb3: lastLineItem && hasSecondaryLines
        })}
      >
        {!collapseImages && (
          <div
            style={
              lastLineItem
                ? { width: IMAGE_DIMENSIONS.width }
                : IMAGE_DIMENSIONS
            }
            className="bg-transparent br2"
          >
            {item.image && (
              <Image
                src={item.image}
                {...IMAGE_DIMENSIONS}
                alt={item.label}
                {...PRIORITY_FIT_CONTAIN}
              />
            )}
          </div>
        )}
        <div
          className={classnames("flex flex-column w-100", {
            ml3: !collapseImages
          })}
        >
          <div className="flex flex-row justify-between">
            {item.label && <div className="f5 fw6 pr1">{item.label}</div>}
            {item.value && <div className="f5 fw6">{item.value}</div>}
          </div>
          {hasSub && (
            <div className="flex flex-row justify-between pt1">
              <div className="f7 f6-l black-60 lh-copy">
                {item.sublabel || ""}
              </div>
              <div className="f6 black-60 pl3 flex-shrink-0">
                {item.subvalue || ""}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  });
};

export default LineItems;
