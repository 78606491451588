import React, { Fragment } from "react";
import Label from "./Label";
import Description from "./Description";
import LoadingSpinner from "components/LoadingSpinner";

const LabelDescription = ({
  nameRoot,
  loading,
  edit,
  label,
  labelKey,
  description,
  descriptionKey,
  showError,
  required
}) => {
  const showLabel = Boolean(label || edit);
  const showDescription = Boolean(description || edit);
  const labelName = `${nameRoot}.${labelKey || "label"}`;
  const descriptionName = `${nameRoot}.${descriptionKey || "description"}`;
  if (!showLabel && !showDescription) {
    return null;
  }

  return (
    <Fragment>
      <div className="w-100 flex flex-row justify-between">
        {showLabel && (
          <Label
            edit={edit}
            name={labelName}
            label={label}
            error={showError}
            required={required}
          />
        )}
        {loading ? (
          <LoadingSpinner customClasses={{ ring: "blue", size: "small" }} />
        ) : null}
      </div>
      {showDescription && (
        <Description edit={edit} name={descriptionName} copy={description} />
      )}
    </Fragment>
  );
};

export default LabelDescription;
