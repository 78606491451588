import React from "react";
import { canRenderImages } from "utils/image";
import { PLACEHOLDER_IMAGE } from "components/Image";

const DEFAULT_STYLES = {
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat"
};

const StandardImage = ({
  src,
  className = "w-100 h5 relative flex items-center justify-center",
  style = DEFAULT_STYLES
}) => {
  const styles = { ...DEFAULT_STYLES, ...style };

  const { canRender, fallback } = canRenderImages(src);
  let imageSrc = PLACEHOLDER_IMAGE;
  if (canRender) {
    imageSrc = src;
  } else if (fallback) {
    imageSrc = fallback;
  }
  if (!imageSrc) {
    return null;
  }

  return (
    <div
      className={className}
      style={{
        backgroundImage: `url(${imageSrc})`,
        ...styles
      }}
    ></div>
  );
};

export default StandardImage;
