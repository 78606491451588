import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Label from "./Label";
import map from "lodash/map";
import startCase from "lodash/startCase";
import { BLUE_PRIMARY, BLUE_SECONDARY } from "utils/styles";
import { getMultiselectProps } from "utils/constants/multiselect";

const DEFAULT_CLASSES = {
  container: ""
};

/*
 * Example options shape
 * [
 *   {
 *     label: "Category Book",
 *     value: 1 (id for the associated model)
 *   }
 * ]
 *
 * Is Multiselect
 * - onChange: () => (change: Array) => {}
 * Not Multiselect
 * - onChange: () => (change: Object) => {}
 */
const modelsToOptions = ({ models, transformer }) =>
  map(models, ({ name, id, ...rest }) => {
    return {
      label: transformer(name),
      value: id,
      ...rest
    };
  });

const MultiSelect = ({
  labelTransform,
  label,
  value,
  disabled,
  options,
  customClasses,
  onChange,
  stateKey,
  component,
  placeholder,
  onClick,
  isMulti,
  components,
  customStyles,
  customStylesClasses,
  closeMenuOnSelect
}) => {
  const ctxCloseMenuOnSelect =
    typeof closeMenuOnSelect === "boolean" ? closeMenuOnSelect : true;
  const multiselectProps = getMultiselectProps({
    customStyles,
    customStylesClasses,
    isMulti
  });
  const classes = { ...DEFAULT_CLASSES, ...customClasses };

  let labelComp;
  if (label) {
    labelComp = <Label label={label} />;
  }
  const transformer = labelTransform || startCase;
  const selections = modelsToOptions({
    models: value,
    transformer
  });
  const allOptions = modelsToOptions({
    models: options,
    transformer
  });
  const containerProps = {
    className: classes.container
  };
  if (disabled && onClick) {
    containerProps.onClick = onClick;
  }

  return (
    <div {...containerProps}>
      {labelComp}
      <Select
        {...multiselectProps}
        closeMenuOnSelect={ctxCloseMenuOnSelect}
        isMulti={isMulti}
        theme={(theme) => ({
          ...theme,
          borderWidth: 1,
          colors: {
            ...theme.colors,
            primary: BLUE_PRIMARY,
            primary75: BLUE_SECONDARY,
            primary50: BLUE_SECONDARY
          }
        })}
        isOptionDisabled={(option) => option.disabled}
        isDisabled={disabled}
        onChange={onChange(stateKey, component, options)}
        options={allOptions}
        placeholder={placeholder}
        /**
         * User facing prompt to clear on backspace
         */
        backspaceToRemoveMessage=""
        removeSelected
        rtl={false}
        simpleValue
        value={selections}
        components={components || {}}
      />
    </div>
  );
};

MultiSelect.propTypes = {
  label: PropTypes.string,
  stateKey: PropTypes.string,
  component: PropTypes.string,
  placeholder: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  customClasses: PropTypes.object,
  customStylesClasses: PropTypes.object,
  customStyles: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  labelTransform: PropTypes.func,
  components: PropTypes.shape({
    Option: PropTypes.any
  })
};

MultiSelect.defaultProps = {
  customClasses: {},
  placeholder: "Select tags",
  isMulti: true,
  onChange: (values) => {
    console.log("**** CSV MultiSelect values by id:", values);
  }
};

export default MultiSelect;
