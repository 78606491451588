import orderBy from "lodash/orderBy";
import omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import prettyBytes from "pretty-bytes";
import { formatUTCTime } from "utils/date";
import { FORM_KEY } from "utils/constants/form";
import { omitAndSanitizeParams } from "components/Form/fields/helpers";
import {
  ATTACHMENT_CONTEXT_TYPE,
  ATTACHMENT_SERVICE
} from "./constants/attachment";
import { getUUID } from "./uuid";

const ATTACHMENT_KEY =
  process.env.NODE_ENV === "production"
    ? "priceblocs-attachment"
    : "priceblocs-dev-attachment";

/**
 * Manually clear all matching storage keys
 * Prod
 * Object.keys(window.localStorage).filter((key) => /^priceblocs-attachment-/.test(key)).forEach((key) => window.localStorage.removeItem(key));
 * Dev
 * Object.keys(window.localStorage).filter((key) => /^priceblocs-dev-attachment-/.test(key)).forEach((key) => window.localStorage.removeItem(key));
 */

export const getMimeTypeLabel = (mimetype = "") => {
  const mimeparts = mimetype.split("/");
  if (mimeparts.length > 1) {
    return mimeparts.slice(1).join("/").toUpperCase();
  } else {
    return mimeparts[0].toUpperCase();
  }
};

export const isImageMimetype = (mimetype = "") =>
  mimetype.split("/")[0].toLowerCase() === "image";

export const getCreateAttachmentScaffold = (
  { uuid = getUUID(), context = ATTACHMENT_CONTEXT_TYPE.CONTENT } = {
    uuid: getUUID(),
    context: ATTACHMENT_CONTEXT_TYPE.CONTENT
  }
) => {
  return {
    [FORM_KEY]: {},
    key: uuid,
    uuid,
    context,
    service: "",
    data: {}
  };
};

export const prepareEditAttachmentInitialValues = ({ attachment }) => {
  return {
    [FORM_KEY]: {},
    id: attachment.id,
    uuid: attachment.uuid,
    data: attachment.data,
    context: attachment.context || ATTACHMENT_CONTEXT_TYPE.CONTENT
  };
};

export const prepareCreateAttachmentsInitialValues = (props) => {
  return {
    attachments: [getCreateAttachmentScaffold(props)]
  };
};

export const prepareAttachmentFromValues = (attachment) => {
  return attachment;
};

export const prepareCreateAttachments = (attachments) => {
  return omitAndSanitizeParams({
    attachments: attachments.map((values) =>
      prepareAttachmentFromValues(values)
    )
  });
};

/**
 * Compare changes on keys for update
 * Note: db will merge the data field but that should be deprecated
 * @param {Object} initial form values
 * @param {Object} current form values
 */
export const prepareUpdateAttachment = (initial, current) => {
  // reduceKeyDifferences

  return omit(current, [FORM_KEY]);
};

export const attachmentSizeLabel = (size) => prettyBytes(size);

export const prepareAttachmentShow = ({ attachment }) => {
  const result = {
    id: attachment.id,
    key: attachment.uuid,
    uuid: attachment.uuid,
    service: attachment.service,
    name: attachment.data.filename,
    size: attachmentSizeLabel(attachment.data.size),
    url: attachment.data.url,
    mimetype: getMimeTypeLabel(attachment.data.mimetype),
    createdAt: formatUTCTime(attachment.created_at)
  };

  return result;
};

export const prepareAttachmentList = ({ attachments, shouldOrder }) => {
  const ctxModels = shouldOrder
    ? orderBy(attachments, ["created_at"], ["desc"])
    : attachments;

  return ctxModels.map((attachment) => {
    return prepareAttachmentShow({
      attachment
    });
  });
};

export const prepareDownloadAttachmentList = ({ attachments, shouldOrder }) => {
  const downloads = attachments.filter(
    ({ context }) => context === ATTACHMENT_CONTEXT_TYPE.DOWNLOAD
  );

  return prepareAttachmentList({ attachments: downloads, shouldOrder });
};

export const formatUploadedAttachment = ({
  attachment,
  file,
  context = ATTACHMENT_CONTEXT_TYPE.CONTENT
}) => {
  const updatedAttachment = {
    ...cloneDeep(attachment),
    data: file,
    context,
    /**
     * TODO: Alternate service depending on uploader
     */
    service: ATTACHMENT_SERVICE.FILESTACK
  };

  return updatedAttachment;
};

const getAttatchmentKey = (key) => `${ATTACHMENT_KEY}-${key}`;

export const setStoreAttatchmentField = ({ key, value }) =>
  typeof window !== "undefined" &&
  window.localStorage.setItem(getAttatchmentKey(key), value);

export const clearStoreAttatchmentField = (key) =>
  typeof window !== "undefined" &&
  window.localStorage.removeItem(getAttatchmentKey(key));

export const getStoreAttatchmentField = (key) =>
  typeof window !== "undefined" &&
  window.localStorage.getItem(getAttatchmentKey(key));
