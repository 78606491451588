import css from "styled-jsx/css";
import { COLORS } from "utils/styles";

export const cellGrow = css`
  :global(.cell-grow) {
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
  }

  :global(.cell-grow:hover),
  :global(.cell-grow:focus) {
    transform: scale(1.0075);
  }

  :global(.cell-grow:active) {
    transform: scale(0.99);
  }
`;

export const cellGrowBig = css`
  :global(.cell-grow-big) {
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
  }

  :global(.cell-grow-big:hover),
  :global(.cell-grow-big:focus) {
    transform: scale(1.1);
  }

  :global(.cell-grow-big:active) {
    transform: scale(0.99);
  }
`;

export const paymentStyles = css`
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }

  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }

  .hidden {
    display: none;
  }

  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }

  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }

  #payment-request-button {
    margin-bottom: 32px;
  }

  /* Buttons and links */
  button {
    background: ${COLORS.blue};
    color: ${COLORS.white};
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }

  button:hover {
    filter: contrast(115%);
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: ${COLORS.white};
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: ${COLORS.blue};
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: ${COLORS.blue};
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }
`;

export const gridContainer = css`
  @media (max-width: 30em) {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }
  @media (min-width: 60em) {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }

  @media (max-width: 30em) {
    .grid-container-2 {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    .grid-container-2 {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }
  @media (min-width: 60em) {
    .grid-container-2 {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }

  @media (max-width: 30em) {
    .grid-container-2xl {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 20px 20px;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    .grid-container-2xl {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 20px 20px;
    }
  }
  @media (min-width: 60em) {
    .grid-container-2xl {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 40px 40px;
    }
  }

  @media (max-width: 30em) {
    .grid-container-3 {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    .grid-container-3 {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }
  @media (min-width: 60em) {
    .grid-container-3 {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }

  @media (max-width: 30em) {
    .grid-container-4 {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    .grid-container-4 {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }
  @media (min-width: 60em) {
    .grid-container-4 {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      gap: 16px 16px;
    }
  }
`;

export const proseMirrorStyles = css`
  :global(.ProseMirror > * + *) {
    margin-top: 0.75em;
  }
  :global(.ProseMirror p.is-editor-empty:first-child::before) {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
  :global(.mention) {
    border: 1px solid #000;
    border-radius: 0.4rem;
    box-decoration-break: clone;
    padding: 0.1rem 0.3rem;
  }
  :global(.ProseMirror) {
    border-color: rgba(0, 0, 0, 0.08);
    border-width: 1px;
  }
  :global(.ProseMirror[contenteditable="true"]) {
    // padding: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.08);
    border-style: solid;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  :global(.ProseMirror) {
    border-color: rgba(0, 0, 0, 0.08);
    border-width: 1px;
  }
  :global(.ProseMirror:focus) {
    border-width: 1px;
    border-color: ${COLORS.blue};
  }
  :global(.ProseMirror ul),
  :global(.ProseMirror ol) {
    padding: 0 1rem;
  }
  :global(.ProseMirror > h1),
  :global(.ProseMirror > h2),
  :global(.ProseMirror > h3),
  :global(.ProseMirror > h4),
  :global(.ProseMirror > h5),
  :global(.ProseMirror > h6) {
    line-height: 1.1;
    color: red;
  }
  :global(.ProseMirror > p) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  :global(.ProseMirror > code) {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
  :global(.ProseMirror > pre) {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }
  :global(.ProseMirror > pre > code) {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
  }
  :global(.ProseMirror > img) {
    max-width: 100%;
    height: auto;
  }
  :global(.ProseMirror > blockquote) {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
  :global(.ProseMirror > hr) {
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
`;
