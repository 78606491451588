import React from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import { THEMES } from "utils/constants/ui";
import classnames from "classnames";

const THEME_CLASS = {
  [THEMES.ERROR]: "b--error",
  [THEMES.WARNING]: "b--warning"
};
const THEME_ICON = {
  [THEMES.ERROR]: "alert",
  [THEMES.WARNING]: "alert"
};

const LINK_COPY_DEFAULT = "Learn more";

const InfoList = ({ items, icon, theme, header }) => {
  const themeClass = THEME_CLASS[theme] || "";
  const themeIcon = THEME_ICON[theme] || icon || "help";
  const Icon = getIconForUID(themeIcon);

  return (
    <div
      className={`w-100 flex flex-column ba br2 hairline-1 pa2 bg-content--secondary ${themeClass}`}
    >
      <div className="flex flex-row pb2">
        <div className="flex flex-shrink-0">
          <Icon size={18} />
        </div>
        <div className="f5 fw6 pl1 lh-title">{header}</div>
      </div>
      <ul className="ma0" style={{ paddingLeft: 18 }}>
        {items.map(({ copy, url, linkCopy }, itemIx) => (
          <li
            key={itemIx}
            className={classnames("w-100 f6 lh-copy", {
              pt1: itemIx
            })}
          >
            {copy}
            {url && (
              <span>
                <a
                  target="_blank"
                  style={{ paddingLeft: 4 }}
                  className="f6 link color-primary fw5 link__decoration--hover"
                  href={url}
                >
                  {linkCopy || LINK_COPY_DEFAULT}
                </a>
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InfoList;
