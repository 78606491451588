import React, { Fragment } from "react";
import classnames from "classnames";
import Pill from "components/Pill";
import { PILL_THEME } from "components/Pill";
import { PRIORITY_FIT_CONTAIN } from "utils/constants/ui";
import Image from "components/Image";

export const IMAGE_DIMENSIONS = { height: 120, width: 120 };

const DEFAULT_CLASSES = {
  image: "flex justify-center dn-ns pb3",
  title: "f5 f4-ns fw5 tc tl-ns pb1 black-60",
  label: "f2 tc tl-ns f1-ns fw6",
  sublabel: "f6 f5-ns tc tl-ns black-60 pt1",
  body: "f6 f5-ns tc tl-ns black-60 pt2 lh-copy",
  content: "w-100 pb4"
};

const Header = ({
  banner,
  badge,
  customClasses,
  firstLineItem: { image, label } = {}
}) => {
  const hasBannerLabel = banner && banner.label;
  const hasBadgeLabel = badge && badge.label;
  const classes = { ...DEFAULT_CLASSES, ...customClasses };

  const pillBadge = hasBadgeLabel && (
    <div className="dib">
      <Pill
        theme={PILL_THEME.SLIM}
        status={badge.status}
        copy={badge.label}
        customClasses={{
          container: "flex-shrink-0",
          copy: "f7 pa2 br2 fw6 flex-shrink-0 items-center"
        }}
      />
    </div>
  );

  return (
    <Fragment>
      <div className="dib dn-ns">{pillBadge}</div>
      {image ? (
        <div className={classes.image}>
          <div
            style={IMAGE_DIMENSIONS}
            className="bg-transparent br2 overflow-hidden"
          >
            <Image
              src={image}
              {...IMAGE_DIMENSIONS}
              alt={label}
              {...PRIORITY_FIT_CONTAIN}
            />
          </div>
        </div>
      ) : null}
      <div
        className={classnames("w-100 flex flex-row", {
          "justify-end pb3": !hasBannerLabel && hasBadgeLabel
        })}
      >
        {hasBannerLabel && (
          <div className={classes.content}>
            {banner.title && (
              <div className={classes.title}>{banner.title}</div>
            )}
            {banner.label && (
              <div className={classes.label}>{banner.label}</div>
            )}
            {banner.sublabel && (
              <div className={classes.sublabel}>{banner.sublabel}</div>
            )}
            {banner.body && <div className={classes.body}>{banner.body}</div>}
          </div>
        )}
        <div className="dn dib-ns flex-shrink-0">{pillBadge}</div>
      </div>
    </Fragment>
  );
};

export default Header;
