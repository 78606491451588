import { SPLIT_CONTAINER_SECTION_NS_CLASS } from "utils/constants/ui";
import { processViewportClasses } from "utils/editor";
import classnames from "classnames";
import { EMBED_CONTAINER_CLASS } from "./constants/embed";
import { getEmbedState } from "./manifest";

export const PAGINATION_STEP = 10;
const SPLIT_CLASS = "__split";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const getCursors = (page, total, step) => {
  const start = page * step;
  let end = start + step;
  if (end > total) {
    end = total;
  }

  return {
    next: Number(page) + 1,
    prev: Number(page) - 1,
    start,
    end,
    pages: Math.ceil(total / step)
  };
};

/**
 * Review what values are being use for embed vs not and what form factor ones are getting parsed
 * @param {Object} param0
 * @returns
 */
export const getPageSplitContainerClasses = ({
  formIsFullScreen,
  embed,
  hasBrandingAssets,
  canActivateSettings,
  hasNavPrev,
  customClasses,
  viewport
} = {}) => {
  const {
    disabledOrSplitLayout,
    enabledSingleLayout,
    enabled: embedEnabled,
    layout: embedLayout
  } = getEmbedState(embed);

  return processViewportClasses(
    {
      background: `${SPLIT_CLASS}-background dn dn-m flex-ns flex-row ${
        formIsFullScreen ? "fixed" : "absolute"
      } top-0 left-0 right-0 bottom-0 overflow-hidden`,
      container: classnames(
        `${SPLIT_CLASS}-container flex flex-column flex-column-m flex-row-ns w-100 relative`,
        {
          "bg-white bg-transparent-ns": disabledOrSplitLayout,
          "bg-transparent": enabledSingleLayout,
          "h-100": !canActivateSettings,
          // Ensure full height within editor
          "preview-styles": canActivateSettings,
          // src/constants/styles/embed.js
          [`${EMBED_CONTAINER_CLASS}-${embedLayout}`]: embedEnabled
        }
      ),
      content: classnames(
        `${SPLIT_CLASS}-content flex flex-column flex-column-m flex-row-ns w-100 center z-1 items-center items-center-m items-start-ns justify-between-ns form__content--paddingTop-ns`,
        {
          "pv4": !hasBrandingAssets && !enabledSingleLayout,
          "pt2 pb5": hasBrandingAssets
        }
      ),
      // Alternate between fixed and absolute due to page and editor environments
      checkoutBar: classnames(
        `${SPLIT_CLASS}-checkoutBar left-0 right-0 top-0 bg-white shadow-0 dib w-100 dn-ns z-2`,
        {
          fixed: !canActivateSettings,
          absolute: canActivateSettings
        }
      ),
      left: classnames(
        `${SPLIT_CLASS}-left flex flex-column w-100 w-50-l ph3 ${
          customClasses && customClasses.left ? customClasses.left : ""
        }`,
        {
          [`${SPLIT_CONTAINER_SECTION_NS_CLASS} sticky-l`]: !enabledSingleLayout,
          "top-2-l": !hasNavPrev,
          "top-3-l": hasNavPrev
        }
      ),
      right: classnames(
        `${SPLIT_CLASS}-right w-100 w-50-l flex flex-column ph3 ${
          customClasses && customClasses.right ? customClasses.right : ""
        }`,
        {
          [`${SPLIT_CONTAINER_SECTION_NS_CLASS}`]: !enabledSingleLayout
        }
      ),
      brandingWrapper: `${SPLIT_CLASS}-brandingWrapper w4 pt3 pt0-ns`,
      resultContainer: classnames(`${SPLIT_CLASS}-resultContainer`, {
        "pt4 pt3-ns": hasBrandingAssets,
        "pt0": !hasBrandingAssets
      }),
      title: `${SPLIT_CLASS}-title f3 f2-ns fw6 tl`,
      subtitle: `${SPLIT_CLASS}-subtitle f4 f3-ns tl lh-copy w-100 center pb3 lh-title`,
      initialPreview: `${SPLIT_CLASS}-initialPreview w-100`,
      desktopCheckoutButton: `${SPLIT_CLASS}-desktopCheckoutButton pb4 pb0-ns pt2-ns dn flex-column flex-ns center justify-end-ns w-100-ns`,
      portalTrigger: `${SPLIT_CLASS}-portalTrigger absolute left-0 right-0 bottom-0 h3 color__gradient dn dib-ns`
    },
    viewport
  );
};
