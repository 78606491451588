import React from "react";
import classnames from "classnames";
import { IMAGE_DIMENSIONS } from ".";

const SubscriptionScheduleItems = ({
  subscriptionScheduleItems,
  collapseImages
}) => {
  const content = subscriptionScheduleItems.map((item, itemIx) => {
    const emphasis =
      (!itemIx && subscriptionScheduleItems.length === 1) ||
      itemIx === subscriptionScheduleItems.length - 1;

    const hasSub = item.sublabel || item.subvalue;

    return (
      <div
        key={itemIx}
        className={classnames("flex flex-row w-100", {
          pt2: itemIx && subscriptionScheduleItems.length > 1
        })}
      >
        {!collapseImages && (
          <div
            style={{ width: IMAGE_DIMENSIONS.width }}
            className={item.image ? "contain bg-center br2" : "bg-transparent"}
          ></div>
        )}
        <div
          className={classnames("flex flex-column w-100 justify-center", {
            pb3: emphasis,
            ml3: !collapseImages
          })}
        >
          <div className="flex flex-row justify-between">
            {item.label && (
              <div
                className={classnames("f6", {
                  fw6: emphasis
                })}
              >
                {item.label}
              </div>
            )}
            {item.value && (
              <div
                className={classnames("f6", {
                  fw6: emphasis
                })}
              >
                {item.value}
              </div>
            )}
          </div>
          {hasSub && (
            <div className="flex flex-row justify-between pt1">
              <div className="f7 black-60 lh-title">{item.sublabel || ""}</div>
              <div className="f7 black-60">{item.subvalue || ""}</div>
            </div>
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="flex flex-column">
      <div className="flex flex-row">
        {!collapseImages && (
          <div
            style={{ width: IMAGE_DIMENSIONS.width }}
            className="bg-transparent"
          ></div>
        )}

        <div
          className={classnames(
            "flex flex-column w-100 justify-center pb3 bt hairline-1 pt3",
            {
              ml3: !collapseImages
            }
          )}
        >
          <div className="f5 fw6">Installments</div>
        </div>
      </div>
      {content}
    </div>
  );
};

export default SubscriptionScheduleItems;
