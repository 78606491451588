import React, { useState, Fragment, useRef } from "react";
import Overlay from "components/Overlay";
import Select from "components/Form/fields/Select";
import CurrencyInput from "components/FormerEditor/CurrencyInput";
import { useFormikContext } from "formik";
import get from "lodash/get";
import {
  formatUnitAmount,
  formattedPriceToUnitAmount,
  getConfigForCurrency
} from "components/FormerEditor/common/currency";
import { CURRENCY_OPTIONS } from "components/FormerEditor/common/constants/currency";
import HoverIconButton from "components/HoverIconButton";
import PriceCalculator from "components/TemplateBuilderForm/PriceCalculator";
import {
  SHORT_INTERVAL_MULTIPLES_MAP,
  OPPOSITE_SHORT_INTERVAL_MULTIPLES_MAP
} from "utils/constants/payment";
import classnames from "classnames";

const SELECT_WIDTH = 84;
const DEFAULT_LABEL = `Price`;

const AmountField = ({
  name,
  value,
  hideLabel,
  label,
  currency,
  currencyPath,
  disabled,
  innerRef,
  withCalculator,
  recurringIntervalPath,
  recurringInterval,
  noBlocksStyle,
  ...props
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();

  const error = get(props, "error") || get(errors, name);
  const currencyInputRef = useRef(null);

  const showError = Boolean(get(touched, name) && error);
  const currencyConfig = getConfigForCurrency(currency);

  const setFormattedAmountValue = (formattedAmount) => {
    if (!get(touched, name)) {
      setFieldTouched(name, true);
    }

    setFieldValue(name, formattedAmount);
  };

  const inputProps = {
    disabled: disabled,
    placeholder: `${currencyConfig.symbol}0.00`,
    type: "text",
    className: `input--common f5 border-box input-reset bw1 br--right pa2 db w-100 br2 lh-copy hairline-2`,
    value: value,
    innerRef: currencyInputRef,
    onChange: ({ currentTarget }) => {
      const value = currentTarget.value;

      setFormattedAmountValue(value);
    }
  };
  const maskOptions = {
    prefix: currencyConfig.symbol
  };
  if (innerRef) {
    inputProps.innerRef = innerRef;
  }

  const formattedPrice = get(values, name);
  const amount = formattedPriceToUnitAmount({
    formattedPrice,
    currency
  });
  const setCurrency = (selection) => {
    setFieldValue(currencyPath, selection);
    if (!get(touched, name)) {
      setFieldTouched(currencyPath, true);
    }
  };

  const setRecurringInterval = (selection) => {
    setFieldValue(recurringIntervalPath, selection);
    if (!get(touched, name)) {
      setFieldTouched(recurringIntervalPath, true);
    }
  };

  const selectProps = {
    options: CURRENCY_OPTIONS,
    stateKey: currencyPath,
    value: currency,
    disabled,
    onChange: () => (evt) => {
      /**
       * Selecting a new currency will require setting both
       * - new currency value
       * - reformatted unit amount
       */
      const selection = evt.currentTarget.value;

      setCurrency(selection);
      const updatedFormattedPrice = formatUnitAmount({
        amount,
        currency: selection
      });
      setFieldValue(name, updatedFormattedPrice);
    },
    labelTransform: (val) => val,
    customClasses: {
      container: "w-100",
      input: `input--common brtl-6 brbl-6`
    }
  };

  return (
    <Fragment>
      <div className="w-100 flex flex-column justify-between items-stretch">
        {!hideLabel && (
          <div className="f6 self-center fw6 w-100">
            {label || DEFAULT_LABEL}
          </div>
        )}
        <div
          className={classnames("f5 w-100", {
            pt2: !hideLabel
          })}
        >
          <div className={"f5 flex flex-row items-stretch"}>
            {withCalculator && (
              <div className="h-auto items-center flex pr1">
                <HoverIconButton
                  onClick={() => setShowOverlay(true)}
                  type="calculator"
                  tooltip="Calculate price"
                />
              </div>
            )}
            <div style={{ width: SELECT_WIDTH, borderRight: "none" }}>
              <Select {...selectProps} />
            </div>
            <CurrencyInput
              key={currency}
              maskOptions={maskOptions}
              {...inputProps}
            />
          </div>
        </div>
      </div>
      {showError && (
        <div key={currency} className="f6 w-100 self-center pt2 tl color-error">
          {error}
        </div>
      )}
      {showOverlay && (
        <Overlay hideOverlay={() => setShowOverlay(false)}>
          <div className="pa3">
            <PriceCalculator
              initialValues={{
                formattedPrice: value,
                currency,
                multiple: recurringInterval
                  ? OPPOSITE_SHORT_INTERVAL_MULTIPLES_MAP[recurringInterval]
                  : SHORT_INTERVAL_MULTIPLES_MAP.MONTH
              }}
              setCurrency={setCurrency}
              action={{
                onClick: (calcValues) => {
                  setFormattedAmountValue(calcValues.formattedAmount);
                  setCurrency(calcValues.currency);
                  if (calcValues.recurringInterval) {
                    setRecurringInterval(calcValues.recurringInterval);
                  }
                  setShowOverlay(false);
                },
                type: "add",
                tooltip: "Use amount"
              }}
            />
          </div>
        </Overlay>
      )}
    </Fragment>
  );
};

export default AmountField;
