import invert from "lodash/invert";
import {
  formatUnitAmount,
  formattedPriceToUnitAmount,
  sanitizeFormattedNumber,
  prettyRoundUnitAmountWithFactor,
  getCurrencyConfigForLocale,
  roundAmount
} from "components/FormerEditor/common/currency";
import { MONTH_COUNT } from "components/FormerEditor/common/constants/currency";
import {
  INTERVAL_LABELS_MAP,
  SHORT_INTERVAL_MULTIPLES_MAP
} from "./constants/payment";
import { RECURRING_INTERVALS } from "components/FormerEditor/common/constants";
import { INPUT_SQUARE_LEFT_CLASSES } from "utils/constants/ui";
export const DISCOUNT = 0;

export const getPerMonthBilledAnnualPrice = (total, currency) => {
  const amount = prettyRoundUnitAmountWithFactor({
    amount: total,
    currency: currency,
    divisor: MONTH_COUNT
  });
  const annualAmount = amount * MONTH_COUNT;

  return {
    perMonthBilledYearlyFormattedPrice: formatUnitAmount({
      amount,
      currency
    }),
    yearBilledYearlyFormattedPrice: formatUnitAmount({
      amount: annualAmount,
      currency
    })
  };
};

export const getMultiple = (value) => {
  const newMultiple = sanitizeFormattedNumber(value);

  return Number.isNaN(newMultiple) ? 0 : newMultiple;
};

export const getRecurringIntervalForMultiple = (value) => {
  const multiple = getMultiple(value);

  const multipleMap = invert(SHORT_INTERVAL_MULTIPLES_MAP);
  return multipleMap[multiple];
};

export const getInitialCalculatorValues = (props) => {
  const currency =
    (props && props.currency) ||
    getCurrencyConfigForLocale().code.toLowerCase();

  const multiple =
    (props && props.multiple) ||
    SHORT_INTERVAL_MULTIPLES_MAP[RECURRING_INTERVALS.MONTH];

  const unitAmount =
    props && props.formattedPrice
      ? formattedPriceToUnitAmount({
          formattedPrice: props.formattedPrice,
          currency
        })
      : 1000;

  const unitFormattedPrice = formatUnitAmount({
    amount: unitAmount,
    currency
  });

  const multipleFormattedPrice = formatUnitAmount({
    amount: roundAmount(unitAmount * multiple),
    currency
  });

  const total = roundAmount(
    unitAmount * multiple * (DISCOUNT ? DISCOUNT / 100 : 1)
  );

  const totalFormattedPrice = formatUnitAmount({
    amount: total,
    currency
  });

  return {
    unitFormattedPrice,
    multipleFormattedPrice,
    totalFormattedPrice,
    currency,
    multiple: multiple.toString(),
    discount: DISCOUNT.toString(),
    ...getPerMonthBilledAnnualPrice(total, currency)
  };
};

export const handleUnitFormattedPriceChange = ({ values, setValues }) => ({
  currentTarget: { value }
}) => {
  const unitUnitAmount = formattedPriceToUnitAmount({
    formattedPrice: value,
    currency: values.currency
  });

  const multipleValue = sanitizeFormattedNumber(values.multiple);
  const multipleUnitAmount = unitUnitAmount * multipleValue;

  const updatedMultipleFormattedPrice = formatUnitAmount({
    amount: roundAmount(multipleUnitAmount),
    currency: values.currency
  });

  const discountValue = sanitizeFormattedNumber(values.discount);
  const newTotal = roundAmount(
    multipleUnitAmount - (multipleUnitAmount * discountValue) / 100
  );
  const updatedTotalFormattedPrice = formatUnitAmount({
    amount: newTotal,
    currency: values.currency
  });

  setValues({
    ...values,
    unitFormattedPrice: value,
    multipleFormattedPrice: updatedMultipleFormattedPrice,
    totalFormattedPrice: updatedTotalFormattedPrice,
    ...getPerMonthBilledAnnualPrice(newTotal, values.currency)
  });
};

export const handleMultipleChange = ({ values, setValues }) => ({
  currentTarget: { value }
}) => {
  const newMultiple = getMultiple(value);

  const unitAmount = formattedPriceToUnitAmount({
    formattedPrice: values.unitFormattedPrice,
    currency: values.currency
  });
  const multipleUnitAmount = unitAmount * newMultiple;
  const updatedMultipleFormattedPrice = formatUnitAmount({
    amount: roundAmount(multipleUnitAmount),
    currency: values.currency
  });

  const discountValue = sanitizeFormattedNumber(values.discount);
  const newTotal = roundAmount(
    multipleUnitAmount - (multipleUnitAmount * discountValue) / 100
  );
  const updatedTotalFormattedPrice = formatUnitAmount({
    amount: newTotal,
    currency: values.currency
  });

  setValues({
    ...values,
    multiple: value,
    multipleFormattedPrice: updatedMultipleFormattedPrice,
    totalFormattedPrice: updatedTotalFormattedPrice,
    ...getPerMonthBilledAnnualPrice(newTotal, values.currency)
  });
};

export const handleMultipleFormattedPriceChange = ({ values, setValues }) => ({
  currentTarget: { value }
}) => {
  const multipleUnitAmount = formattedPriceToUnitAmount({
    formattedPrice: value,
    currency: values.currency
  });

  const updatedUnitFormattedPrice = formatUnitAmount({
    amount: roundAmount(
      multipleUnitAmount / sanitizeFormattedNumber(values.multiple)
    ),
    currency: values.currency
  });

  const discountValue = sanitizeFormattedNumber(values.discount);
  const newTotal = roundAmount(
    multipleUnitAmount - (multipleUnitAmount * discountValue) / 100
  );
  const updatedTotalFormattedPrice = formatUnitAmount({
    amount: newTotal,
    currency: values.currency
  });

  setValues({
    ...values,
    unitFormattedPrice: updatedUnitFormattedPrice,
    totalFormattedPrice: updatedTotalFormattedPrice,
    multipleFormattedPrice: value,
    ...getPerMonthBilledAnnualPrice(newTotal, values.currency)
  });
};

export const handleDiscountChange = ({ values, setValues }) => ({
  currentTarget: { value }
}) => {
  const discountValue = sanitizeFormattedNumber(value);

  const multipleUnitAmount = formattedPriceToUnitAmount({
    formattedPrice: values.multipleFormattedPrice,
    currency: values.currency
  });
  const newTotal = roundAmount(
    multipleUnitAmount - (multipleUnitAmount * discountValue) / 100
  );
  const updatedTotalFormattedPrice = formatUnitAmount({
    amount: newTotal,
    currency: values.currency
  });

  setValues({
    ...values,
    discount: value,
    totalFormattedPrice: updatedTotalFormattedPrice,
    ...getPerMonthBilledAnnualPrice(newTotal, values.currency)
  });
};

export const handleCurrencyChange = ({ values, setValues }) => {
  const unitUnitAmount = formattedPriceToUnitAmount({
    formattedPrice: values.unitFormattedPrice,
    currency: values.currency
  });

  const value = formatUnitAmount({
    amount: unitUnitAmount,
    currency: values.currency
  });

  const multipleValue = sanitizeFormattedNumber(values.multiple);
  const multipleUnitAmount = unitUnitAmount * multipleValue;

  const updatedMultipleFormattedPrice = formatUnitAmount({
    amount: roundAmount(multipleUnitAmount),
    currency: values.currency
  });

  const discountValue = sanitizeFormattedNumber(values.discount);
  const newTotal = roundAmount(
    multipleUnitAmount - (multipleUnitAmount * discountValue) / 100
  );
  const updatedTotalFormattedPrice = formatUnitAmount({
    amount: newTotal,
    currency: values.currency
  });

  setValues({
    ...values,
    unitFormattedPrice: value,
    multipleFormattedPrice: updatedMultipleFormattedPrice,
    totalFormattedPrice: updatedTotalFormattedPrice,
    ...getPerMonthBilledAnnualPrice(newTotal, values.currency)
  });
};

export const getPreviewConfig = ({ values, inputProps }) => {
  const previewAction = {
    type: "add",
    tooltip: `Use amount and ${
      INTERVAL_LABELS_MAP[RECURRING_INTERVALS.YEAR]
    } interval`
  };
  const copyPriceValue = sanitizeFormattedNumber(values.totalFormattedPrice);
  const totalFormattedPriceInputProps = {
    ...inputProps,
    className: INPUT_SQUARE_LEFT_CLASSES,
    value: values.totalFormattedPrice
  };

  const perMonthBilledYearlyFormattedPriceInputProps = {
    ...inputProps,
    disabled: true,
    maskOptions: {
      prefix: ""
    },
    className: INPUT_SQUARE_LEFT_CLASSES,
    value: values.yearBilledYearlyFormattedPrice
  };

  return [
    {
      header: "STANDARD",
      label: `${formatUnitAmount({
        amount:
          formattedPriceToUnitAmount({
            formattedPrice: values.totalFormattedPrice,
            currency: values.currency
          }) / MONTH_COUNT,
        currency: values.currency
      })} per ${RECURRING_INTERVALS.MONTH}`,
      copy: {
        value: copyPriceValue,
        tooltip: "Copy amount",
        toast: "Copied amount to clipboard"
      },
      action: previewAction,
      currency: values.currency,
      input: {
        disabled: true,
        maskOptions: {
          prefix: ""
        },
        ...totalFormattedPriceInputProps
      }
    },
    {
      header: "PRETTY",
      label: `${values.perMonthBilledYearlyFormattedPrice} per ${RECURRING_INTERVALS.MONTH}`,
      copy: {
        value: values.yearBilledYearlyFormattedPrice,
        tooltip: "Copy amount",
        toast: "Copied amount to clipboard"
      },
      action: previewAction,
      currency: values.currency,
      input: {
        disabled: true,
        maskOptions: {
          prefix: ""
        },
        ...perMonthBilledYearlyFormattedPriceInputProps
      }
    }
  ];
};
