import React from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import classnames from "classnames";

const MultiselectOption = ({
  innerProps,
  isDisabled,
  data,
  getStyles,
  ...props
}) => {
  const optionStyles = getStyles("option", props);
  const SourceIcon = data.icon && getIconForUID(data.icon);
  return (
    <div
      {...innerProps}
      style={{
        ...innerProps.style,
        backgroundColor: optionStyles.backgroundColor
      }}
      className={classnames(
        "flex flex-column items-center-ns pa2 bg-white--hover",
        {
          "cursor-not-allowed": isDisabled,
          "bb hairline-1": true
        }
      )}
    >
      <div className="flex flex-row w-100">
        {data.icon && (
          <div
            className="flex items-center justify-center"
            style={{ width: 32, height: 32 }}
          >
            {SourceIcon ? <SourceIcon /> : null}
          </div>
        )}
        <div className="flex flex-row items-center justify-between w-100">
          <div
            className={classnames("f6 w-100 pr1 lh-title", {
              "color-text--secondary": isDisabled,
              "fw6": data.description,
              "fw5": !data.description
            })}
          >
            {data.label}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between w-100">
        <div
          style={{
            paddingLeft: data.icon ? 6 : 0,
            paddingTop: data.icon ? 0 : 4
          }}
          className={classnames("f6 lh-title w-100", {
            "color-text--secondary": isDisabled
          })}
        >
          {data.description}
        </div>
      </div>
    </div>
  );
};

export default MultiselectOption;
