import React, { Fragment } from "react";
import Header from "./Header";
import LineItems from "./LineItems";
import DiscountItems from "./DiscountItems";
import SubscriptionScheduleItems from "./SubscriptionScheduleItems";
import ShippingItems from "./ShippingItems";
import TaxItems from "./TaxItems";
import AmountItems from "./AmountItems";

export const IMAGE_DIMENSIONS = { height: 52, width: 52 };

const CheckoutPreview = ({
  badge,
  banner,
  collapseImages,
  lineItems,
  discountItems,
  amountItems,
  taxItems,
  shippingItems,
  subscriptionScheduleItems,
  customClasses = {},
  hideHeader = false
}) => {
  const hasLineItems = lineItems && lineItems.length > 0;
  const hasDiscounts = discountItems && discountItems.length > 0;
  const hasTaxes = taxItems && taxItems.length > 0;
  const hasShippings = shippingItems && shippingItems.length > 0;
  const hasSecondaryLines = hasTaxes || hasDiscounts || hasShippings;
  const hasAmounts = amountItems && amountItems.length > 0;
  const hasSubscriptionScheduleItems =
    subscriptionScheduleItems && subscriptionScheduleItems.length > 0;

  const firstLineItem = lineItems && lineItems[0];
  const checkoutImage = firstLineItem && firstLineItem.image;

  return (
    <Fragment>
      {!hideHeader && (
        <Header
          banner={banner}
          badge={badge}
          firstLineItem={firstLineItem}
          image={checkoutImage}
          customClasses={customClasses.header}
        />
      )}
      {hasLineItems && (
        <LineItems
          lineItems={lineItems}
          hasSecondaryLines={hasSecondaryLines}
          collapseImages={collapseImages}
          customClasses={customClasses.lineItems}
        />
      )}
      {hasDiscounts && (
        <DiscountItems
          discountItems={discountItems}
          collapseImages={collapseImages}
          customClasses={customClasses.discountItems}
        />
      )}
      {hasShippings && (
        <ShippingItems
          discountItems={discountItems}
          shippingItems={shippingItems}
          collapseImages={collapseImages}
          customClasses={customClasses.shippingItems}
        />
      )}
      {hasTaxes && (
        <TaxItems
          hasDiscounts={hasDiscounts}
          taxItems={taxItems}
          collapseImages={collapseImages}
          customClasses={customClasses.taxItems}
        />
      )}
      {hasAmounts && (
        <AmountItems
          collapseImages={collapseImages}
          amountItems={amountItems}
          hasTaxes={hasTaxes}
          hasDiscounts={hasDiscounts}
          customClasses={customClasses.amountItems}
        />
      )}
      {hasSubscriptionScheduleItems && (
        <SubscriptionScheduleItems
          subscriptionScheduleItems={subscriptionScheduleItems}
          collapseImages={collapseImages}
          customClasses={customClasses.subscriptionScheduleItems}
        />
      )}
    </Fragment>
  );
};

export default CheckoutPreview;
