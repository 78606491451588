import React, { Fragment } from "react";
import Select from "components/Form/fields/Select";
import { useFormikContext } from "formik";
import { BILLING_PERIOD_OPTIONS_WITH_COUNT } from "utils/constants/payment";
import get from "lodash/get";
import ConnectedField from "components/ConnectedField";
import { integerTransformer } from "utils/form";

const BillingPeriodField = ({
  label,
  intervalName,
  intervalCountName,
  value,
  disabled,
  noBlocksStyle,
  ...props
}) => {
  const {
    touched,
    errors,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();

  const intervalError =
    get(props, `intervalError`) || get(errors, intervalName);
  const intervalCountError =
    get(props, `intervalCountError`) || get(errors, intervalCountName);

  const showIntervalError = Boolean(
    get(touched, intervalName) && intervalError
  );
  const showIntervalCountError = Boolean(
    get(touched, intervalCountName) && intervalCountError
  );
  const showError = showIntervalError || showIntervalCountError;
  const error = showIntervalError
    ? intervalError
    : showIntervalCountError
    ? intervalCountError
    : null;

  const selectProps = {
    options: BILLING_PERIOD_OPTIONS_WITH_COUNT,
    stateKey: intervalName,
    disabled,
    value,
    onChange: (key) => (evt) => {
      const selection = evt.currentTarget.value;
      setFieldValue(key, selection);
      if (!get(touched, intervalName)) {
        setFieldTouched(key, true);
      }
    },
    customClasses: {
      container: "h-100",
      input: `input--common brtr-6 brbr-6`
    },
    noBlocksStyle
  };

  return (
    <Fragment>
      <div className="w-100 flex flex-column justify-between items-center">
        <div className="flex flex-row justify-between items-center f5 w-100">
          <div className="f6 w-100 fw6">{label ? label : `Billing every`}</div>
          <div className="flex flex-row flex-shrink-0">
            <div className="w2-5">
              <ConnectedField
                customClasses={{
                  input: `input--common brtl-6 brbl-6`
                }}
                inputMode="numeric"
                hideError
                name={intervalCountName}
                transformer={integerTransformer}
              />
            </div>
            <div className="w4">
              <Select {...selectProps} />
            </div>
          </div>
        </div>
      </div>
      {showError && (
        <div className="f6 w-100 self-center pt2 tl color-error">{error}</div>
      )}
    </Fragment>
  );
};

export default BillingPeriodField;
