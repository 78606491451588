import React, { Fragment } from "react";
import { fieldProps } from "utils/form";
import { Field, useFormikContext } from "formik";
import get from "lodash/get";
import classnames from "classnames";
import FieldError from "components/FieldError";
const DEFAULT_CLASSES = {
  input: "f5 border-box input-reset ba pa2 db w-100 br2 lh-copy b--black-20",
  label: "field__label fw5 db"
};
const ConnectedField = ({
  name,
  type,
  inputMode,
  label,
  onKeyPress,
  transformer,
  customClasses,
  innerRef,
  hideError,
  disabled,
  placeholder
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const {
    touched,
    errors,
    setFieldTouched,
    setFieldValue
  } = useFormikContext();
  const error = get(errors, name);

  const showError = get(touched, name) && error && !Boolean(hideError);
  const inputProps = {
    name,
    type,
    inputMode,
    setFieldValue,
    setFieldTouched,
    innerRef,
    disabled,
    placeholder
  };
  if (onKeyPress) {
    inputProps.onKeyPress = onKeyPress;
  }
  if (transformer) {
    inputProps.transformer = transformer;
  }
  if (type) {
    inputProps.type = type;
  }

  return (
    <Fragment>
      {label && <label className={classes.label}>{label}</label>}
      <Field
        className={classnames(classes.input, {
          "b--red": showError
        })}
        {...fieldProps(inputProps)}
      />
      {!hideError && <FieldError name={name} />}
    </Fragment>
  );
};

export default ConnectedField;
