import React from "react";
import classnames from "classnames";
import { IMAGE_DIMENSIONS } from ".";

const AmountItems = ({
  amountItems,
  collapseImages,
  hasTaxes,
  hasDiscounts
}) => {
  return amountItems.map((item, itemIx) => {
    const emphasis =
      (!itemIx && amountItems.length === 1) ||
      itemIx === amountItems.length - 1;

    const hasSub = item.sublabel || item.subvalue;

    return (
      <div
        key={itemIx}
        className={classnames("flex flex-row w-100", {
          "pt3": !hasTaxes && !hasDiscounts,
          "pt2 mt1": itemIx && amountItems.length > 1
        })}
      >
        {!collapseImages && (
          <div
            style={{ width: IMAGE_DIMENSIONS.width }}
            className={item.image ? "contain bg-center br2" : "bg-transparent"}
          ></div>
        )}
        <div
          className={classnames("flex flex-column w-100 justify-center", {
            "bt hairline-1 pt3": !itemIx,
            "pb3": emphasis,
            "ml3": !collapseImages
          })}
        >
          <div className="flex flex-row justify-between">
            {item.label && (
              <div
                className={classnames("f5", {
                  fw6: emphasis
                })}
              >
                {item.label}
              </div>
            )}
            {item.value && (
              <div
                className={classnames("f5", {
                  fw6: emphasis
                })}
              >
                {item.value}
              </div>
            )}
          </div>
          {hasSub && (
            <div className="flex flex-row justify-between pt1">
              <div className="f6 black-60 lh-title">{item.sublabel || ""}</div>
              <div className="f6 black-60">{item.subvalue || ""}</div>
            </div>
          )}
        </div>
      </div>
    );
  });
};

export default AmountItems;
