import React from "react";
import { useFormikContext } from "formik";
import classnames from "classnames";
import { getDateInputAdjustedUTC, MOBILE_TS_FORMAT } from "utils/date";
import get from "lodash/get";
import format from "date-fns/format";
import { DATE_INPUT_TYPE } from "utils/constants/datepicker";
import { INPUT_CLASSES } from "utils/constants/ui";
import { useIsMounted } from "utils/hooks";

const KEY_PREFIX = "mobileDatePicker";

const MobileDatePicker = ({
  name,
  onChange,
  disabled = false,
  readOnly = false,
  pickerProps = {}
}) => {
  const { values } = useFormikContext();
  const value = get(values, name);
  const isMounted = useIsMounted();

  /**
   * For an input of type (datetime-local | date)
   * We need to format it's presentation value accordingly
   */
  const showTime = pickerProps.showTimeInput;
  const dateFormat = showTime
    ? MOBILE_TS_FORMAT.DATETIME_LOCAL
    : MOBILE_TS_FORMAT.DATE;
  const pickerValue = value ? new Date(value) : new Date();
  const presentationValue = format(pickerValue, dateFormat);

  const dateValues = {
    value: presentationValue
  };
  /**
   * Parent datepicker will pass the now date adjusted on timeframe
   * - future -> min of now minus an hour
   * - past -> max of now minus an hour
   */
  if (pickerProps.min) {
    dateValues.min = format(pickerProps.min, dateFormat);
  }
  if (pickerProps.max) {
    dateValues.max = format(pickerProps.max, dateFormat);
  }
  const type = showTime ? DATE_INPUT_TYPE.DATETIME_LOCAL : DATE_INPUT_TYPE.DATE;

  /**
   * Safari iOS needs to re-render the input between mounted / unmounted
   * Otherwise the input is not initialized as a valid date/datetime-local input
   * So we alternate the key based on mount state to trigger the re-render
   */
  const key = `${KEY_PREFIX}-${isMounted.current}`;

  const inputProps = {
    type,
    key,
    name,
    disabled,
    readOnly,
    ...dateValues,
    className: classnames(INPUT_CLASSES, {
      "o-50": disabled,
      "bg-content--secondary": disabled
    }),
    onChange: (evt) => {
      if (evt.currentTarget.value) {
        onChange(
          getDateInputAdjustedUTC({
            showTime,
            ts: evt.currentTarget.value
          })
        );
      }
    }
  };

  return (
    <div className="dib w-100 flex">
      <input {...inputProps} />
    </div>
  );
};

export default MobileDatePicker;
