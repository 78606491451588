import React, { useState, useMemo, useEffect, Fragment, useRef } from "react";
import { AUTO_ATTRS } from "components/Form/fields/constants";
import TextareaAutosize from "react-textarea-autosize";
import { useDebounce } from "utils/hooks";
import FieldError from "components/FieldError";
import { useFormikContext } from "formik";
import classnames from "classnames";
import get from "lodash/get";
import { DEFAULT_DEBOUNCE } from "utils/constants/form";
import { getFieldNameRoot } from "utils/form";
import LabelDescription from "components/Form/fields/LabelDescription";
import { INPUT_CLASSES } from "utils/constants/ui";

const DebounceTextarea = ({
  uid,
  edit,
  inputValue,
  valueSet,
  debounce,
  onDebounce,
  placeholder,
  label,
  description,
  name,
  hideError,
  innerRef,
  error,
  required,
  customStyles,
  customClasses,
  readonly,
  ...props
}) => {
  const [firstMount, setFirstMount] = useState(false);
  const formRef = useRef(null);
  const ctxRef = innerRef || formRef;
  const { touched, errors, values } = useFormikContext();

  const ctxError = error || get(errors, name);
  const formVal = get(values, name);
  const nameRoot = getFieldNameRoot(name);
  const isTouched = get(touched, name);
  const showError = Boolean(isTouched && ctxError && !hideError);
  /**
   * ReadOnly when
   * - readonly is true
   * - initial value present
   */
  const readOnly = useMemo(() => {
    return Boolean(inputValue && readonly);
  }, [readonly]);
  const [value, setValue] = useState(inputValue || "");
  const ctxDebounce = debounce || DEFAULT_DEBOUNCE;

  useEffect(() => {
    if (firstMount) {
      valueSet && valueSet(value);
      onDebounce && onDebounce(value);
    } else {
      setFirstMount(true);
    }
  }, [useDebounce(value, ctxDebounce)]);

  useEffect(() => {
    if (!name) {
      console.warn("Need a name to correctly unset textarea field");
    }

    let syncVal;
    if (!formVal && ctxRef.current && ctxRef.current.value) {
      syncVal = "";
    } else if (ctxRef.current && formVal !== ctxRef.current.value) {
      syncVal = formVal;
    }
    if (typeof syncVal !== "undefined") {
      ctxRef.current.value = syncVal;
      setValue(syncVal);
    }
  }, [formVal]);

  /**
   * If the uid has changed (e.g. updated in form state)
   * And if its different to the input value
   * Then set value to input value as its the source of truth when it has changed
   * (i.e. after an external value set event)
   */
  useEffect(() => {
    if (uid && inputValue !== value) {
      setValue(inputValue);
    }
  }, [useDebounce(uid, 0)]);

  const disabled = Boolean(props.disabled);

  const fieldInput = {
    className: classnames(customClasses || INPUT_CLASSES, {
      "b--red": showError,
      "b--black-20": !showError,
      "cursor-not-allowed": disabled
    }),
    placeholder: placeholder || "",
    minRows: 1,
    value: value,
    ...AUTO_ATTRS,
    onChange: (evt) => {
      setValue(evt.target.value);
    },
    onBlur: (evt) => {
      setValue(evt.target.value);
    },
    ref: ctxRef,
    ...props,
    disabled,
    readOnly
  };

  return (
    <Fragment>
      <LabelDescription
        nameRoot={nameRoot}
        edit={edit}
        label={label}
        description={description}
        showError={showError}
        required={required}
      />
      <TextareaAutosize {...fieldInput} />
      {showError && <FieldError error={ctxError} name={name} />}
    </Fragment>
  );
};
export default DebounceTextarea;
