import React from "react";
import { useFormikContext } from "formik";
import {
  SCHEDULE_TYPE,
  SCHEDULE_LABEL_MAP,
  DEFAULT_RECURRING
} from "utils/constants/price";
import SegmentedControl from "components/SegmentedControl";
import get from "lodash/get";
import classnames from "classnames";

/**
 * ScheduleField
 * - This field is responsible for toggling between one time and recurring schedule
 * - So it will either set recurring to be an object of a one_time string
 * - The actual values for recurring.[interval, interval_count, usage_type] will be set via the BillingPeriodField which is shown only when recurring is not one_time
 * @param {Object} params
 * @param {String} params.name - the recurring key path (e.g. prices[0].recurring)
 * @param {String} params.label
 * @param {String} params.disabled
 */

const ScheduleField = ({
  name,
  value,
  label,
  disabled,
  hideLabel,
  noBlocksStyle
}) => {
  const { touched, setFieldValue, setFieldTouched } = useFormikContext();

  const scheduleProps = {
    options: Object.values(SCHEDULE_TYPE).map((scheduleUID) => ({
      uid: scheduleUID,
      label: SCHEDULE_LABEL_MAP[scheduleUID]
    })),
    disabled,
    theme: "slim",
    value,
    noBlocksStyle
  };

  if (!disabled) {
    /**
     * Note: this sets the whole recurring object - not a key of the recurring object
     * Set to null when one time
     */
    scheduleProps.onClick = (_, selection) => {
      if (!get(touched, name)) {
        setFieldTouched(name, true);
      }
      const recurringForSchedule =
        selection === SCHEDULE_TYPE.ONE_TIME ? null : DEFAULT_RECURRING;

      setFieldValue(name, recurringForSchedule);
    };
  }

  const mainLabel = label || `Schedule`;

  return (
    <div className="w-100 flex flex-column justify-between items-center">
      {!hideLabel && <div className="f6 w-100 fw6">{mainLabel}</div>}
      <div
        className={classnames("f5 w-100", {
          pt2: !hideLabel
        })}
      >
        <SegmentedControl {...scheduleProps} />
      </div>
    </div>
  );
};

export default ScheduleField;
