import React from "react";
import classnames from "classnames";
import { IMAGE_DIMENSIONS } from ".";

const TaxItems = ({ collapseImages, taxItems, hasDiscounts }) => {
  return (
    <div className="flex flex-row w-100">
      {!collapseImages && (
        <div style={IMAGE_DIMENSIONS} className="bg-transparent"></div>
      )}
      <div
        className={classnames("flex flex-column w-100 justify-center pb3", {
          "ml3": !collapseImages,
          "bt hairline-1 pt3": !hasDiscounts
        })}
      >
        {taxItems.map((item, itemIx) => (
          <div
            key={itemIx}
            className={classnames("flex flex-row justify-between", {
              pt2: itemIx
            })}
          >
            {item.label && (
              <div className="f6 fw5 black-60 pt1">{item.label}</div>
            )}
            {item.value && (
              <div className="f6 fw5 black-60 pt1">{item.value}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaxItems;
