import React, { useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { COLORS } from "utils/styles";
import IconButton from "components/IconButton";
import Padder from "components/Padder";
import { PHONE } from "utils/constants/ui";
import {
  shouldBeTransparent,
  getOverlayStyles,
  unfixBody,
  setOverlayBodyClass,
  unsetOverlayBodyClass
} from "utils/view";
import { OVERFLOW_TOUCH } from "utils/constants/ui";
import { withRouter } from "next/router";
import { OVERLAY_CONTAINER, OFF_OVERLAY_CLASS } from "utils/constants/overlay";
import { getUUID } from "utils/uuid";
import { useViewport } from "utils/context";
import ReactDOM from "react-dom";

const DEFAULT_CLASSES = {
  container: `${OVERLAY_CONTAINER} fixed w-100 top-0 bottom-0 right-0 left-0 z-7`,
  overlay: `${OFF_OVERLAY_CLASS} w-100 h-100 relative`,
  content:
    "contentSection fixed absolute-l w-100 w-50-l top-0 right-0 bottom-0 bg-white bw2 b--black-10 shadow-0 overflow-y-scroll overflow-x-hidden",
  action: "fixed pa1 pa2-ns z-5 ba br3 hairline-1 bg-white bg-white--hover"
};

const Overlay = (props) => {
  const {
    uuid,
    modelUUID,
    router,
    hideOverlay,
    children,
    customClasses
  } = props;
  const { isSmall } = useViewport();
  const overlayUUID = useMemo(() => getUUID(), []);
  const overlayScrollRef = useRef(null);

  /**
   * Reset scroll top when uuid changes
   * - important for mobile so that we dont get a fully scrolled down page
   * - which looks blank when navigating between models
   */
  useEffect(() => {
    if (overlayScrollRef && overlayScrollRef.current) {
      overlayScrollRef.current.scrollTop = 0;
    }

    return () => {
      unfixBody();
    };
  }, [uuid, modelUUID]);

  useEffect(() => {
    setOverlayBodyClass(overlayUUID);
    return () => {
      unsetOverlayBodyClass(overlayUUID);
    };
  }, []);

  const hideOffContentClick = (evt) => {
    if (evt.target.classList.contains(OFF_OVERLAY_CLASS)) {
      hideOverlay(router);
    }
  };

  const classes = { ...DEFAULT_CLASSES, ...customClasses };

  const iconProps = {
    iconType: "close",
    iconProps: {
      size: isSmall ? 20 : 24
    },
    colors: {
      inactive: COLORS.primary
    },
    onClick: () => hideOverlay(router)
  };
  const beTransparent = shouldBeTransparent(overlayUUID);

  return ReactDOM.createPortal(
    <div
      className={classes.container}
      style={getOverlayStyles(beTransparent)}
      onClick={hideOffContentClick}
    >
      <div className={classes.overlay}>
        <div
          style={OVERFLOW_TOUCH}
          ref={overlayScrollRef}
          className={classes.content}
        >
          <div style={{ top: 8, right: 8 }} className={classes.action}>
            <IconButton {...iconProps} />
          </div>
          {children}
          <Padder theme="visible" />
          <style jsx>{`
            @media (min-width: ${PHONE + 1}px) {
              .contentSection {
                max-width: 760px;
                border-left-style: solid;
              }
            }
          `}</style>
        </div>
      </div>
    </div>,
    document.body
  );
};

Overlay.propTypes = {
  overlayClick: PropTypes.func,
  buttonClick: PropTypes.func
};

export default withRouter(Overlay);
