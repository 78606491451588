import React, { Fragment } from "react";
import { Formik } from "formik";
import {
  getConfigForCurrency,
  sanitizeFormattedNumber
} from "components/FormerEditor/common/currency";
import {
  handleUnitFormattedPriceChange,
  handleMultipleChange,
  handleDiscountChange,
  getInitialCalculatorValues,
  handleMultipleFormattedPriceChange,
  getPreviewConfig
} from "utils/calculator";
import CurrencyInput from "components/FormerEditor/CurrencyInput";
import CopyToClipboard from "components/CopyToClipboard";
import {
  RECURRING_INTERVALS,
  INTERVAL_LABELS_MAP
} from "components/FormerEditor/common/constants";
import HoverIconButton from "components/HoverIconButton";
import { INPUT_SQUARE_LEFT_CLASSES } from "utils/constants/ui";
import Analytics, { COPY_AMOUNT } from "utils/analytics";

const PreviewHeader = () => (
  <Fragment>
    <div className="f5 fw5 w-100">Billed yearly preview</div>
    <div className="f6 w-100 pt2 lh-copy">{`Preview how the current total would appear as a per ${
      RECURRING_INTERVALS.MONTH
    } price when billed ${
      INTERVAL_LABELS_MAP[RECURRING_INTERVALS.YEAR]
    }. We've calculated both the standard and rounded version of the price for pretty presentation.`}</div>
  </Fragment>
);

const PriceCalculator = ({ action, tracking, initialValues }) => {
  const calculatorValues = getInitialCalculatorValues(initialValues);

  return (
    <Formik initialValues={calculatorValues}>
      {({ values, setValues }) => {
        const currencyConfig = getConfigForCurrency(values.currency);
        const maskOptions = {
          prefix: currencyConfig.symbol
        };
        const commonInputProps = {
          placeholder: `${currencyConfig.symbol}0.00`,
          type: "text",
          className:
            "f5 border-box input-reset bw1 pa2 db w-100 br2 lh-copy hairline-2"
        };
        const unitFormattedPriceInputProps = {
          ...commonInputProps,
          value: values.unitFormattedPrice,
          onChange: handleUnitFormattedPriceChange({ values, setValues })
        };

        const multipleInputProps = {
          ...commonInputProps,
          value: values.multiple,
          placeholder: "x0",
          onChange: handleMultipleChange({ values, setValues })
        };

        const multipleFormattedPriceInputProps = {
          ...commonInputProps,
          value: values.multipleFormattedPrice,
          onChange: handleMultipleFormattedPriceChange({ values, setValues })
        };

        const discountInputProps = {
          ...commonInputProps,
          value: values.discount,
          placeholder: "0%",
          onChange: handleDiscountChange({ values, setValues })
        };

        const totalFormattedPriceInputProps = {
          ...commonInputProps,
          className: INPUT_SQUARE_LEFT_CLASSES,
          value: values.totalFormattedPrice
        };

        const copyPriceValue = sanitizeFormattedNumber(
          values.totalFormattedPrice
        );

        const INPUT_CONFIG = [
          {
            label: "Unit price",
            inputProps: {
              maskOptions,
              ...unitFormattedPriceInputProps
            }
          },
          {
            label: "Multiple",
            inputProps: {
              maskOptions: {
                prefix: "x"
              },
              ...multipleInputProps
            }
          },
          {
            label: "Total",
            customClasses: {
              container: "flex flex-row items-center pv3"
            },
            inputProps: {
              maskOptions,
              ...multipleFormattedPriceInputProps
            }
          },
          {
            label: "Discount",
            customClasses: {
              container: "flex flex-row items-center pt3 bt hairline-1"
            },
            inputProps: {
              maskOptions: {
                prefix: "",
                suffix: "%",
                integerLimit: 3
              },
              ...discountInputProps
            }
          }
        ];

        const PREVIEW_CONFIG = getPreviewConfig({
          values,
          inputProps: commonInputProps
        });

        return (
          <div className="w-100 flex flex-column">
            <div className="flex flex-row pb3 bb hairline-1">
              <div className="f4 fw5">Calculator</div>
            </div>
            {INPUT_CONFIG.map((config, configIx) => {
              const DEFAULT_CLASSES = {
                container: "flex flex-row items-center pt3"
              };
              const classes = { ...DEFAULT_CLASSES, ...config.customClasses };

              return (
                <div key={configIx} className={classes.container}>
                  <div className="f5 fw5 w-100">{config.label}</div>
                  <div className="calculator__input--container flex-shrink-0">
                    <CurrencyInput {...config.inputProps} />
                  </div>
                </div>
              );
            })}
            <div className="flex flex-row items-center pv3">
              <div className="f5 fw5 w-100">Total</div>
              <div className="calculator__input--container flex-shrink-0 flex flex-row items-stretch">
                <div className="h-auto items-center flex pr1">
                  {action ? (
                    <HoverIconButton
                      type={action.type}
                      tooltip={action.tooltip}
                      onClick={() => {
                        Analytics.track(COPY_AMOUNT, tracking);
                        action.onClick({
                          currency: values.currency,
                          formattedAmount: values.totalFormattedPrice
                        });
                      }}
                    />
                  ) : (
                    <CopyToClipboard
                      value={copyPriceValue}
                      tracking={{
                        event: COPY_AMOUNT,
                        data: {
                          ...tracking
                        }
                      }}
                      tooltip="Copy amount"
                      toast="Copied amount to clipboard"
                    />
                  )}
                </div>
                <div
                  style={{ width: 52 }}
                  className="h-auto items-center flex h-auto br2 br--left bl bt b--black-20 bb items-center justify-center"
                >
                  <div className="flex items-center h-100">
                    <div className="f7 ttu fw5 ph2">{values.currency}</div>
                  </div>
                </div>
                <CurrencyInput
                  disabled={true}
                  maskOptions={{
                    prefix: ""
                  }}
                  {...totalFormattedPriceInputProps}
                />
              </div>
            </div>
            <div className="flex flex-column w-100 bt hairline-1 pt3">
              <PreviewHeader />
              {PREVIEW_CONFIG.map((previewConfig, previewConfigIx) => {
                const copyInput = {
                  currency: values.currency,
                  formattedAmount: previewConfig.input.value,
                  recurringInterval: RECURRING_INTERVALS.YEAR
                };
                const copyTracking = {
                  ...tracking,
                  ...copyInput
                };
                return (
                  <Fragment key={previewConfigIx}>
                    <div className="f7 ttu fw5 w-100 pt3 lh-copy">
                      {previewConfig.header}
                    </div>
                    <div className="flex flex-row items-center pt2">
                      <div className="f6 fw5 w-100">{previewConfig.label}</div>
                      <div className="calculator__input--container flex-shrink-0 flex flex-row items-stretch">
                        <div className="h-auto items-center flex pr1">
                          {action ? (
                            <HoverIconButton
                              type={previewConfig.action.type}
                              tooltip={previewConfig.action.tooltip}
                              onClick={() => {
                                Analytics.track(COPY_AMOUNT, copyTracking);
                                action.onClick(copyInput);
                              }}
                            />
                          ) : (
                            <CopyToClipboard
                              {...previewConfig.copy}
                              tracking={{
                                event: COPY_AMOUNT,
                                data: copyTracking
                              }}
                            />
                          )}
                        </div>
                        <div
                          style={{ width: 52 }}
                          className="h-auto items-center flex h-auto br2 br--left bl bt b--black-20 bb items-center justify-center"
                        >
                          <div className="flex items-center h-100">
                            <div className="f7 ttu fw5 ph2">
                              {previewConfig.currency}
                            </div>
                          </div>
                        </div>
                        <CurrencyInput {...previewConfig.input} />
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            <style jsx>
              {`
                .calculator__input--container {
                  width: 180px;
                }
              `}
            </style>
          </div>
        );
      }}
    </Formik>
  );
};

export default PriceCalculator;
