import React, { useState, useRef, useEffect, Fragment } from "react";
import { useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { getPickDatePrompt, getDatepickerAdjustedUTC } from "utils/date";
import get from "lodash/get";
import classnames from "classnames";
import ReactDOM from "react-dom";
import { getFieldNameRoot, getFieldPath } from "utils/form";
import { STATE_KEYS } from "utils/constants/state";
import { US_DATE_TIME_FORMAT } from "utils/constants/datepicker";
import { INPUT_CLASSES } from "utils/constants/ui";
import { useEmbed } from "utils/hooks/embed";
import { getEmbedState } from "utils/manifest";

// https://popper.js.org/docs/v2/
const EMBED_POPPER_MODIFIERS = [
  {
    name: "offset",
    options: {
      offset: [0, -20]
    }
  }
];

const DatePickerWrapper = ({
  value,
  onChange,
  setOpen,
  disabled = false,
  readOnly = false,
  pickerProps,
  embed
}) => {
  const pickerRef = useRef(null);
  useEffect(() => {
    if (pickerRef && pickerRef.current) {
      pickerRef.current.setOpen(true);
    }
  }, []);

  const showTime = pickerProps.showTimeInput;
  /**
   * Using the portal
   * - We need to portal the portal here so that its set on the document body outside the bounds of the Editor / component its nested within
   * - When single layout embed is enabled then withPortal should be false
   */
  const { enabledSingleLayout } = getEmbedState(embed);
  const withPortal = !enabledSingleLayout;

  const props = {
    selected: value,
    /**
     * Display none on the picker input because we simulate it in the 'note' component in the parent component below
     */
    className: "dn",
    timeIntervals: 1,
    onChange: (selectedDate) =>
      onChange(
        getDatepickerAdjustedUTC({
          showTime,
          date: selectedDate
        })
      ),
    fixedHeight: true,
    showMonthDropdown: true,
    showPopperArrow: false,
    showYearDropdown: true,
    dropdownMode: "select",
    withPortal,
    /**
     * Auto close only when theres no need to also select time
     */
    shouldCloseOnSelect: !showTime,
    // Filter time doesnt dynamically update on date change so we disable for now
    // filterTime: (time) => {
    //   const currentDate = new Date();
    //   const selectedDate = new Date(time);

    //   return currentDate.getTime() < selectedDate.getTime();
    // },
    onCalendarClose: () => {
      setTimeout(() => setOpen(false), 0);
    },
    disabled,
    readOnly
  };

  if (enabledSingleLayout) {
    props.popperModifiers = EMBED_POPPER_MODIFIERS;
  }

  if (pickerProps.minDate) {
    props.minDate = pickerProps.minDate;
  }
  if (pickerProps.maxDate) {
    props.maxDate = pickerProps.maxDate;
  }
  if (showTime) {
    props.showTimeInput = true;
  }

  return <DatePicker ref={pickerRef} {...props} />;
};

/**
 * We wrap the desktop modal picker in an independent modal due to a component nesting z-index issue within the Editor UI
 * Without this modal - other on page fixed elements like browser bezel and sidebar navigation are stacked above the main element
 */
const DesktopDatePicker = ({
  name,
  onChange,
  disabled = false,
  readOnly = false,
  pickerProps = {}
}) => {
  const [open, setOpen] = useState(false);
  const { values } = useFormikContext();
  const embed = useEmbed();
  const { enabledSingleLayout } = getEmbedState(embed);

  /**
   * If there is a value it should be UTC
   */
  const value = get(values, name);
  const nameRoot = getFieldNameRoot(name);
  const formatPath = getFieldPath(
    nameRoot,
    STATE_KEYS.DATEPICKER.PRESENTATION_FORMAT
  );
  /**
   * Component will use this Date object internally
   */
  const pickerValue = value ? new Date(value) : new Date();
  /**
   * User will see this value in the picker
   */
  const formatValue = get(values, formatPath) || US_DATE_TIME_FORMAT;
  const presentationValue = getPickDatePrompt(pickerValue, formatValue);

  const pickerWrapper = (
    <DatePickerWrapper
      value={pickerValue}
      setOpen={setOpen}
      onChange={onChange}
      name={name}
      disabled={disabled}
      readOnly={readOnly}
      embed={embed}
      pickerProps={pickerProps}
    />
  );

  return (
    <Fragment>
      <div className="w-100 relative">
        <input
          name="datepicker"
          style={{ maxHeight: 44 }}
          value={presentationValue}
          disabled={disabled}
          /**
           * Input is readonly
           * - dont expect the user to manually enter a value but we need to retain the input element so that it can be tabbed into into on mobile
           * - will be set via the datepicker select component
           */
          readOnly
          onClick={() => !disabled && !open && setOpen(true)}
          onFocus={() => !disabled && !open && setOpen(true)}
          className={classnames(INPUT_CLASSES, {
            "bg-white--hover": !disabled,
            "bg-content--secondary": disabled
          })}
        />
      </div>
      {/**
       * NOTE:
       * - When not using ReactDOM.createPortal & portal to handle insert then there is a slight UI shift on datepicker open
       */}
      {open
        ? enabledSingleLayout
          ? pickerWrapper
          : ReactDOM.createPortal(pickerWrapper, document.body)
        : null}
    </Fragment>
  );
};

export default DesktopDatePicker;
