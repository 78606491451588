import React from "react";

const ActionRowLoader = () => (
  <div className="ba bg-white br2 shadow-0 flex flex-row items-center hairline-1">
    <div className="pa2 flex">
      <div className="h2 w2 shimmer self-start"></div>
    </div>
    <div className="pv2 flex w-100">
      <div className="h2 w-100 shimmer"></div>
    </div>
    <div className="pa2 flex">
      <div className="h2 w3 shimmer"></div>
    </div>
  </div>
);

export default ActionRowLoader;
