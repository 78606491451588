import React from "react";
import classnames from "classnames";
import { IMAGE_DIMENSIONS } from ".";

const ShippingItems = ({ collapseImages, shippingItems, discountItems }) => {
  return (
    <div className="flex flex-row w-100">
      {!collapseImages && (
        <div style={IMAGE_DIMENSIONS} className="bg-transparent"></div>
      )}
      <div
        className={classnames("flex flex-column w-100 justify-center pb3", {
          "ml3": !collapseImages,
          "bt hairline-1 pt3": discountItems.length === 0
        })}
      >
        {shippingItems.map((item, itemIx) => {
          const hasSub = item.sublabel || item.subvalue;
          return (
            <div
              key={itemIx}
              className={classnames("flex flex-column justify-between", {
                pt2: itemIx
              })}
            >
              <div className="flex flex-row justify-between">
                {item.label && (
                  <div className="f6 fw5 black-60 pt1">{item.label}</div>
                )}
                {item.value && (
                  <div className="f6 fw5 black-60 pt1">{item.value}</div>
                )}
              </div>
              {hasSub && (
                <div className="flex flex-row justify-between pt1">
                  <div className="f7 black-60 pt1">{item.sublabel || ""}</div>
                  <div className="f7 black-60 pt1">{item.subvalue || ""}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShippingItems;
