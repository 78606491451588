import React from "react";
import classnames from "classnames";
import { UI_THEME, getBlocksPrefix } from "utils/constants/ui";
import { SegmentedControlPropTypes } from "utils/propTypes";

const getRowStyles = ({ count, rowCount, maxWidth }) => {
  let width = `${(1 / count) * 100}%`;
  if (rowCount === 2) {
    width = "50%";
  }

  return {
    width,
    maxWidth
  };
};

const getLinkStyles = ({ theme }) => ({
  height: theme === UI_THEME.SLIM ? 32 : 4
});

const getSegmentClasses = ({
  value,
  option,
  index,
  rowCount,
  optionCount,
  disabled,
  noBlocksStyle
}) => {
  const isSelected = value === option.uid;

  let linkClasses;
  const common = classnames(`f6 link-ns dib w-100 lh-copy`, {
    [`${getBlocksPrefix(noBlocksStyle)}ba--primary ${getBlocksPrefix(
      noBlocksStyle
    )}color-primary bw3 fw6`]: isSelected,
    "hairline-1": !isSelected,
    "bg-white--hover": !disabled
  });

  if (rowCount === 1) {
    linkClasses = classnames(common, {
      // Left side border radius
      "ba bw1 br2 br--left": index === 0,
      "brl-0": index === 1 && optionCount === 2,
      // Middle Cells
      "bt bb br bw1": index !== 0 && index !== optionCount - 1,
      // Right side border radius
      "ba bw1 bl-0 br2 br--right": index === optionCount - 1,
      "cursor-not-allowed": disabled
    });
  } else if (rowCount === 2) {
    linkClasses = classnames(`${common} pv2`, {
      "ba bw1 brtl-2": index === 0,
      "ba bw1 brtr-2 bl-0": index === 1,
      "ba bw1 brbl-2 bt-0": index === 2,
      "ba bw1 brbr-2 bt-0 bl-0": index === 3,
      "cursor-not-allowed": disabled
    });
  }

  return linkClasses;
};

const SegmentedControl = ({
  options,
  maxWidth,
  disabled,
  theme,
  name,
  value,
  onClick,
  noBlocksStyle,
  rowCount = 1
}) => {
  const optionCount = options.length;
  const rowStyle = getRowStyles({ count: optionCount, rowCount, maxWidth });

  const links = options.map((option, index) => {
    const linkStyles = getLinkStyles({ value, option, theme, disabled });
    const linkClasses = getSegmentClasses({
      value,
      option,
      index,
      optionCount,
      rowCount,
      disabled,
      noBlocksStyle
    });

    const listItemProps = {
      style: rowStyle,
      className: classnames("dib", {
        pointer: !disabled && onClick
      })
    };
    if (!disabled) {
      listItemProps.onClick = () => {
        if (!disabled) {
          onClick(name, option.uid);
        }
      };
    }

    return (
      <li key={index} {...listItemProps}>
        <a style={linkStyles} className={linkClasses}>
          <div className="flex h-100 items-center justify-center w-100">
            <div
              style={{ lineHeight: 0 }}
              className="dib self-center user-select-n ph3 f6"
            >
              {option.label}
            </div>
          </div>
        </a>
      </li>
    );
  });

  return <ul className="list ma0 pa0 tc mb0">{links}</ul>;
};

SegmentedControl.propTypes = SegmentedControlPropTypes;

export default SegmentedControl;
