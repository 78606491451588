import React from "react";
import EditableContent from "components/EditableContent";
import PropTypes from "prop-types";

const DEFAULT_CLASSES = {
  copy: "field__description f7 db mb1 lh-copy"
};

const Description = ({ copy, name, edit, customClasses, formatter }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };

  const content = formatter ? formatter(copy) : copy;

  return (
    <div htmlFor={copy} className={classes.copy}>
      <EditableContent editable={edit} sync={{ name }}>
        {content}
      </EditableContent>
    </div>
  );
};

Description.propTypes = {
  copy: PropTypes.string,
  name: PropTypes.string,
  edit: PropTypes.bool,
  customClasses: PropTypes.object,
  formatter: PropTypes.func
};

export default Description;
