import React from "react";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { formatValidationError } from "utils/form";

const DEFAULT_CLASSES = {
  copy: "field__sublabel fw5 red"
};

const FieldError = ({ name, customClasses, error }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const { errors, touched } = useFormikContext();

  const ctxError = error || get(errors, name);
  const showError = Boolean(get(touched, name) && ctxError);

  return showError ? (
    <div className={classes.copy}>{formatValidationError(ctxError)}</div>
  ) : null;
};

export default FieldError;
